import React from 'react'

export default function Blockquote({ data }) {
  const { author, excerpt } = data;
  return (
    <div className="blockquote">
      <div className="blockquote--wrapper">
        <div className="blockquote--quote">&ldquo;</div>
        <div className="blockquote--text">
          <div className="blockquote--content">
            <p>
              {excerpt}
            </p>
          </div>
          <div className="blockquote--caption">
            <span>
              {author}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
