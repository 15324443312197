import React from 'react';
import Flowline from '../layout/Flowline';
// import Blockquote, { BlockquoteItem } from '../elements/carousels/Blockquote';
import Blockquote from '../elements/Blockquote';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';


export default function Testimonials({ data }) {

  const backgroundImage = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "walk-way.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
        id
      }
    }
  `)

  const testimonialBG = getImage(backgroundImage.file);
  const stackedTestimonialBG = [`linear-gradient(rgba(177,151,107,.5),rgba(177,151,107,.5))`, testimonialBG];

  return (
    <BgImage Tag="section" image={stackedTestimonialBG} className="testimonials">
      <div className="testimonials--background-wrap">
        <div className="testimonials--background-wrap__frame"></div>
      </div>
      <div className="testimonials--wrapper">
        <div className="container">
          <div className="title">
            <span className="title--sub">
              testimonials
            </span>
            <div className="title--wrapper">
              <h3>What our clients are saying</h3>
              <span className="flowline"></span>
            </div>
          </div>
          <Flowline />
          {data.edges.map(({ node }) => {
            return <Blockquote data={node} key={node.id} />
          })}
          <div className="testimonials--more">
          <Link to="/testimonials" className="primary-btn">
            Read More
          </Link>
        </div>
        </div>
      </div>
    </BgImage>
  );
}
